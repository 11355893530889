import { HttpClient } from '@angular/common/http';
import { Injectable, Signal } from '@angular/core';
import { User } from 'app/core/user/user.types';
import { Observable, map, tap } from 'rxjs';
import { SignalStateService } from '../service/signal-state-service';

interface UserState {
    user: User;
}
@Injectable({ providedIn: 'root' })
export class UserService extends SignalStateService<UserState> {
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        super({
            user: {} as User,
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get user(): Signal<User> {
        return this.select('user');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User> {
        return this._httpClient.get<User>('/api/users/own').pipe(
            tap((user) => {
                this.setState({ user });
            })
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('/api/users', user).pipe(
            map((user) => {
                this.setState({ user });
            })
        );
    }
}
